import React, { useCallback, useState, useEffect, useRef } from 'react'
import InputMask from "react-input-mask"
import emailjs from 'emailjs-com'
import styles from './Discount.module.scss'
import { FormValidation } from '../../hooks/FormValidation'

export const Discount = ({popupOpen}) => {

    const handleForm = FormValidation()
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const form = useRef()

    useEffect(() => {
        handleForm.isValid ? setButtonDisabled(false) : setButtonDisabled(true)
    }, [handleForm.isValid])

    const sendData = useCallback((evt) => {
        evt.preventDefault();
            emailjs.sendForm('service_z6b0mkd', 'template_420mpyk', form.current, 'foBjX7KBy1eFfNfjH')
            .then((result) => {
            }, (error) => {
                console.log(error.text);
            }); 
        handleForm.resetForm()
        popupOpen()
    }, [handleForm, popupOpen] )

    return (
        <section className={styles.main}>
                <div className={styles.box_text}>
                    <h2 className={styles.title_info}>Асфальтирование <span>дорог</span></h2>
                    <p className={styles.text}>Асфальтирование является одним из важных этапов при строительстве дорог и тротуаров. Этот процесс заключается в покрытии поверхности специальным материалом, который представляет собой сочетание щебня, песка и асфальта. Данный материал обладает высокой прочностью и устойчивостью к различным видам воздействий, таким как температурные перепады, образование трещин и механические повреждения.</p>
                    <p className={styles.text}>Хорошо выполненное асфальтирование обеспечивает безопасность для движения транспорта и пешеходов, а также создает эстетически приятный вид окружающей среды.</p>
                </div>
                <div className={styles.container}>
                    <h2 className={styles.title}>Закажите выезд специалиста на объект</h2>
                    <form className={styles.form} onSubmit={sendData} ref={form}>
                            <div className={styles.box_input}>
                                <label className={styles.label}>Введите имя
                                    <input
                                        className={`${styles.input} ${handleForm.errors.name && styles.wrong}`}
                                        type='text' 
                                        name='name'
                                        minLength='2'
                                        value={handleForm.values.this}
                                        onChange={handleForm.handleChange} 
                                        pattern='^[A-Za-zА-Яа-яЁё\s\-]{2,30}$'
                                        required/>
                                </label>
                            </div>
                            <span className={styles.input_error}>{handleForm.errors.name}</span>
                            <div className={styles.box_input}>
                                <label className={styles.label}>Введите телефон       
                                    <InputMask
                                        className={`${styles.input} ${handleForm.errors.phone && styles.wrong}`}
                                        type='tel' 
                                        name='phone'
                                        value={handleForm.values.this}
                                        onChange={handleForm.handleChange}  
                                        mask={'+7\\(999) 999-99-99'}  
                                        pattern='\+7\s?[\(][0-9]{3}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}'
                                        required/>
                                </label>
                            </div>            
                            <span className={styles.input_error}>{handleForm.errors.phone}</span>
                            <button className={`${styles.button_form} ${buttonDisabled && styles.button_disabled}`}type='submit'>отправить <span className={styles.glare}></span></button>
                        </form>
                </div>
        </section>
        
    )
}