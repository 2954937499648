import React from 'react'
import styles from './InitialPage.module.scss'
import { motion } from "framer-motion"

const titleAnimation = {
    hidden: {
        x: -800,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: {ease: "easeOut", duration: 1 }
    }
}

const subtitleAnimation = {
    hidden: {
        x: 800,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: {ease: "easeOut", duration: 1 }
    }
}


export const InitialPage = ({isOpen}) => {
    return (
        <motion.section 
        className={styles.main} 
        id='initial'
        initial='hidden'
        whileInView='visible'
        viewport={{once: true}} 
        >
                <div className={styles.box}>
                    <div className={styles.overlay}>
                        <div className={styles.container}>
                            <motion.h1 className={styles.title} variants={titleAnimation}>Производство, продажа и&#160;укладка асфальта</motion.h1>
                            <motion.ul className={styles.list} variants={subtitleAnimation}>
                                <li className={styles.item}>
                                    <div className={styles.boxs}>
                                        <p className={styles.check}>&#10003;</p>
                                        <span className={styles.pulse}></span>
                                    </div>
                                    <p className={styles.item_text}>Укладка асфальта и асфальтовой крошки от 1 метра</p>
                                </li>
                                <li className={styles.item}>
                                    <div className={styles.boxs}>
                                        <p className={styles.check}>&#10003;</p>
                                        <span className={styles.pulse}></span>
                                    </div>
                                    <p className={styles.item_text}>Фиксация бюджета в договоре (никаких скрытых платежей)</p>
                                </li>
                                <li className={styles.item}>
                                    <div className={styles.boxs}>
                                        <p className={styles.check}>&#10003;</p>
                                        <span className={styles.pulse}></span>
                                    </div>
                                    <p className={styles.item_text}>Собственный парк техники. Возможность круглосуточной работы</p>
                                </li>
                            </motion.ul>
                            <button className={styles.button} type='button' onClick={isOpen}>оставить заявку <span className={styles.glare}></span></button>
                        </div>
                    </div>
                </div>  
        </motion.section>
    )
}