import React from 'react'
import styles from './Advantages.module.scss'
import { motion } from "framer-motion"

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5}
    } 
}


export const Advantages = ({isOpen}) => {

    return (
        <motion.section 
        className={styles.main} 
        id='Advantages'
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.05, once: true}}
        >
        <div className={styles.container}>
        <motion.h2 className={styles.title} variants={titleAnimation}>Почему <span> выбирают нас?</span></motion.h2>
        <motion.ul className={styles.list}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <p className={styles.number}>01</p>
                        <h3 className={styles.item_title}>Высокое качество</h3>
                        <p className={styles.item_text}>Наши специалисты обладают большим практическим опытом и знаниями в области дородного строительства. Мы всегда выполняем поставленную задачу качественно.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <p className={styles.number}>02</p>
                        <h3 className={styles.item_title}>Точно в срок </h3>
                        <p className={styles.item_text}>Всегда точно соблюдаем сроки закрепленные договором. Мы выполним работы тогда, когда это удобно вам в любой день и любое время, без выходных и праздников.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <p className={styles.number}>03</p>
                        <h3 className={styles.item_title}>Индивидуальный подход</h3>
                        <p className={styles.item_text}>Мы индивидуально подходим к каждому проекту и клиенту. Учитываем потребности и требования  и стремится предоставить самое эффективное решение, соответствующее потребностям и бюджету.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <p className={styles.number}>04</p>
                        <h3 className={styles.item_title}>Доступные цены</h3>
                        <p className={styles.item_text}>Cобственное производство, большой парк техники и отсутствие посредников позволяют нам держать цены на доступном для клиентов уровне.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <p className={styles.number}>05</p>
                        <h3 className={styles.item_title}>Свой парк техники</h3>
                        <p className={styles.item_text}>Наличие собственного парка специальной техники и современного оборудования позволяет выполнять работы в режиме 24/7.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <p className={styles.number}>06</p>
                        <h3 className={styles.item_title}>Комплексный подход</h3>
                        <p className={styles.item_text}>Мы осуществляем полный спектр услуг, включающих асфальтирование и ремонт дорог, тротуаров и парковочных зон, а также благоустройство территорий.</p>
                    </motion.li>
                </motion.ul>
        </div>   
    </motion.section>
    )
}