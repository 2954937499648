import React from 'react'
import { motion } from "framer-motion"
import styles from './Laying.module.scss'
import image from '../../image/Laying/img_1.webp'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

export const Laying = () => {

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            id='Obuchenie'             
            >
            <div className={styles.container}>
                <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>Укладка <span>асфальта</span></h2>
                        <p className={styles.text}>&#8195;Укладка асфальтового покрытия является достаточно сложным и трудоемким процессом. От качества выполняемой работы напрямую зависит срок службы дорожного полотна, его практичность и надежность. Только качественное асфальтовое покрытие способно прослужить максимально долго, обеспечивая безопасность дорожного движения. Потому выполнение подобной работы стоит доверить профессионалам.</p>
                    </motion.div>
                    <motion.img className={styles.image} src={image} alt='металл' variants={imageAnimation}/>
            </div>
        </motion.section>
        
        
    )
}